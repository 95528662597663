import React, {Component} from "react"
import {graphql, StaticQuery} from 'gatsby';
import Layout from "components/Layout/Layout"
import {Container} from "reactstrap"
import styled from 'styled-components'
import {media} from "utils/Media"
import Footer from "components/Layout/Footer"
import Img from "gatsby-image/withIEPolyfill";

const WelcomePage = styled.div`
    position: relative;
    width: 100%;
    
    @media ${media.lg} {
        overflow: auto;
        height: 100vh;
    }
    
    .thank-you {
      margin-top: 3rem;
      text-align: center;
      color: ${props => props.theme.colors.yellow};
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      
      p {
        font-size: 1rem;
      }
      
      .big {
        color: ${props => props.theme.colors.blue};
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }
    
    .topImage {
        padding-top: 51px;
    }
`

class ThankYouPage extends Component {
    render() {
        return (
            <Layout slug="thank-you" title="Thank you for registering" page="thank-you">
                <WelcomePage>
                    <Img fadeIn={true} fluid={this.props.data.bg.childImageSharp.fluid}
                         className="topImage d-block" alt=""/>

                    <Container fluid={true}>
                        <Container>
                            <div className="thank-you">
                                <h2 className="big mb-4">Thank you {this.props.location.state ? this.props.location.state.name : ""}</h2>

                                <p>for signing up to receive ticket alerts for ‘To Kill a Mockingbird’ which is coming to London from 10 March 2022.</p>
                                {/*<Link className="BuyTicketButton" to="/tickets/">Get Tickets</Link>*/}
                            </div>
                            <Footer/>
                        </Container>
                    </Container>
                </WelcomePage>
            </Layout>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
    query {
        bg: file(relativePath: { eq: "top-art.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
    `}
        render={data => (
            <ThankYouPage location={props.location} data={data}/>
        )}
    />
)